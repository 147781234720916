.root {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.root > svg {
    width: 100px;
    height: initial;
}

.root .ant-form-item {
    margin-bottom: 0;
}

.form {
    width: 100%;
    max-width: 300px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(50px);
    padding: 40px 20px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    box-shadow: 1px 1px 12px 1px #ccc;
}

.form :global .btn {
    margin: 0;
}

.form :global input {
    background: transparent;
    color: #fff;
}

.form :global .input-custom input {
    padding-left: 5px;
}

.form :global input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.form :global .ant-input-affix-wrapper .anticon.ant-input-password-icon {
    color: rgba(255, 255, 255, 0.45) !important;
}

.form :global .ant-input-affix-wrapper .anticon.ant-input-password-icon:hover {
    color: rgba(255, 255, 255, 1) !important;
}