* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

ul, li {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Nunito', sans-serif;
    overflow-x: hidden;
    min-height: 100vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(253.4deg, #0072ff -3.22%, #820db6 101.86%) no-repeat fixed;
    background-size: cover;
    color: #fff;
}

@media print {
    body {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(253.4deg, #0072ff -3.22%, #820db6 101.86%) no-repeat;
    }

    header {
        display: none;
    }

    .page-break, .btn-print {
        display: block;
        page-break-before: always;
    }
}

.container {
    max-width: 1470px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}

.pb-container {
    padding-bottom: 45px;
}

.title {
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    margin: 30px 0;
}

.head-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.head-title .title {
    text-align: left;
}

.not-found {
    font-size: 28px;
    text-align: center;
    width: 100%;
    margin: 25px 0;
}

.preloader {
    width: 200px;
    height: 200px;
}
.preloader,
.preloader span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.preloader span {
    box-sizing: border-box;
    display: block;
    border: 2px solid transparent;
    border-radius: 50%;

    animation: 1s spin linear infinite;
}
.preloader span:nth-of-type(1) {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-top-color: #00c8ff;
    animation-duration: 2.4s;
}
.preloader span:nth-of-type(2) {
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-top-color: rgba(0, 200, 255, 0.7);
    animation-duration: 2s;
}
.preloader span:nth-of-type(3) {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-top-color: rgba(0, 200, 255, 0.5);
    animation-duration: 1.6s;
}
.preloader span:nth-of-type(4) {
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border-top-color: rgba(0, 200, 255, 0.4);
    animation-duration: 1.2s;
}
.preloader span:nth-of-type(5) {
    top: 25px;
    left: 25px;
    right: 25px;
    bottom: 25px;
    border-top-color: rgba(0, 200, 255, 0.3);
    animation-duration: .8s;
}

.ant-modal-mask .ant-picker-input > input::placeholder {
    color: rgba(0, 0, 0, 0.88) !important;
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.input-custom {
    background: transparent !important;
    color: #fff;
}

.input-custom::placeholder, .input-custom .ant-picker-input input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.input-custom:hover, .input-custom:focus {
    border: 1px solid #00c8ff;
}

.select-custom .ant-select-selector {
    background: transparent !important;
}

.ant-select-dropdown .ant-select-item-empty {
    color: #fff;
    text-align: center;
}

.ant-select-selector:hover, .ant-select-selector:focus {
    border: 1px solid #00c8ff;
}

.select-custom .ant-select-selector:hover, .select-custom .ant-select-selector:focus {
    border-color: #00c8ff !important;
}

.select-custom .ant-select-selection-item,
.ant-select-selection-search-input,
.ant-select-item-option,
.ant-empty-description,
.ant-picker-input input {
    color: #fff !important;
}

.ant-picker-suffix {
    color: rgba(255, 255, 255, 0.8) !important;
}

.ant-select-item-option-active, .ant-select-item-option-selected {
    background-color: rgba(255, 255, 255, 0.6) !important;
}

.select-custom .ant-select-selection-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.select-custom .ant-select-arrow {
    color: rgba(255, 255, 255, 0.8);
}

.ant-select-dropdown {
    border: 1px solid #00c8ff;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(253.4deg, #0072ff -3.22%, #820db6 101.86%) no-repeat;
}

.ant-picker-panel-container {
    border: 1px solid #00c8ff !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(253.4deg, #0072ff -3.22%, #820db6 101.86%) no-repeat !important;
}

.ant-picker-header,
.ant-picker-header button,
.ant-picker-content th,
.ant-picker-content td {
    color: #fff !important;
}

.ant-picker-cell.ant-picker-cell-in-view {
    color: rgba(255, 255, 255, 0.88) !important;
}

.ant-picker-cell {
    color: rgba(255, 255, 255, 0.25) !important;
}

.ant-picker-footer {
    border-top: 1px solid rgba(0, 200, 255, 0.33) !important;
}

.input-custom-popup .ant-picker-time-panel-cell-inner {
    color: #fff !important;
}

.input-custom-popup .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    color: #000 !important;
}

.input-custom .ant-input-number-input, .input-custom .ant-input-number-input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.input-custom .ant-input-number-handler-wrap {
    background: transparent;
}

.input-custom .ant-input-number-handler-wrap span {
    border-color: #00c8ff;
}

.input-custom .ant-input-number-handler-wrap span path {
    fill: #fff;
}

.search-custom .ant-input-affix-wrapper,
.search-custom input, .search-custom .ant-btn {
    background-color: transparent;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-picker-dropdown .ant-picker-time-panel-column::after {
    display: none;
}

.search-custom input {
    color: #fff !important;
}

.search-custom input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.search-custom .ant-input-affix-wrapper:hover {
    border: 1px solid #00c8ff;
}

.search-custom .ant-input-clear-icon {
    color: rgba(255, 255, 255, 0.6);
}

.search-custom .ant-input-clear-icon:hover {
    color: rgba(255, 255, 255, 0.45);
}

.search-custom .ant-btn {
    color: rgba(255, 255, 255, 0.8) !important;
}

.search-custom .ant-btn:hover {
    border: 1px solid #00c8ff;
    background-color: #00c8ff;
}

.chk-custom svg {
    width: 20px;
    height: auto;
}

.chk-custom .ant-checkbox-inner {
    background: transparent;
    border: 1px solid #00c8ff;
}

.chk-custom .ant-checkbox-wrapper span {
    display: flex;
    align-items: center;
}

.chk-custom .ant-checkbox {
    top: 0;
}

.chk-custom {
    display: flex;
    align-items: center;
    gap: 5px;
}

.form-fpi {
    max-width: 400px;
    margin: 0 auto;
}

.btn {
    appearance: none;
    max-width: 250px;
    width: 100%;
    height: 50px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    background-image: linear-gradient(to right, #00d2ff 0%, #3a7bd5  51%, #00d2ff  100%);
    transition: 0.5s;
    background-size: 200% auto;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
}

.btn:hover {
    background-position: right center;
    box-shadow: 0 0 30px 1px rgba(0,210,255,1);
}

.league-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
}

.league-item {
    padding: 2px;
    border-radius: 15px;
    width: calc(100% / 4 - 30px);
    position: relative;
    background: rgba(250, 250, 250, 0.1);
    border: 2px solid #00c8ff;
    box-shadow: inset 0 0 0 0 #00c8ff;
    transition: all .3s;
}

.league-item:hover, .league-item:focus {
    box-shadow: inset 0 0 20px 1px #00c8ff;
}


.league-item h2 {
    text-transform: uppercase;
}
.league-item__buttons {
    position: absolute;
    right: 20px;
    bottom: 25px;
    z-index: 100;
    display: flex;
    align-items: center;
    gap: 12px;
}

.league-item__btn {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 23px;
    cursor: pointer;
}

.league-item__btn:nth-child(1), .league-item__btn:nth-child(2) {
    transition: all .4s ease-in-out;
    transform: translateX(10px);
    opacity: 0;
    pointer-events: none;
}

.league-item:hover .league-item__btn:nth-child(1), .league-item:hover .league-item__btn:nth-child(2) {
    pointer-events: initial;
    transform: translateX(0);
    opacity: 1;
}

.league-cart {
    text-align: center;
    color: #fff;
    position: relative;
    height: 100%;
    border-radius: inherit;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    min-height: 150px;
    cursor: pointer;
    text-decoration: none;
}

.league-cart--mod {
    padding: 40px 10px;
}

.color-black input {
    color: #000 !important;
}

.ant-upload-list-item-name {
    color: #fff;
}

.ant-upload-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
}

.ant-upload-list-item path {
    fill: #fff;
}

.upload-custom .ant-btn {
    background: transparent;
    border: 1px solid #00c8ff;
    color: #fff;
}

.cm-editor {
    height: 100%;
    background: rgba(22, 27, 34, 0.2);
}

.cm-editor .cm-gutters {
    background: rgba(22, 27, 34, 0.2);
    color: #0072ff;
    border-right: 1px solid #00c8ff;
}

.readOnly .cm-editor .cm-gutters {
    border-right: 1px solid darkgreen;
}

.cm-editor .cm-gutterElement {
    background: transparent;
}

.cm-editor .cm-line {
    color: #00c8ff;
}

.btn-back-match {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 18px;
    transition: all .3s;
    cursor: pointer;
}

.btn-back-match:hover {
    color: #00c8ff;
}

.readOnly .cm-editor .cm-activeLine {
    background: transparent;
}

.readOnly .cm-editor .cm-line {
    color: darkgreen;
}

.tabs {
    display: flex;
    align-items: center;
}

.tabs button {
    background: transparent;
    border: 1px solid #00c8ff;
    cursor: pointer;
    color: #fff;
    padding: 10px;
    transition: all .3s;
}

.tabs button.is-active {
    background-color: #00c8ff;
    color: #000;
    pointer-events: none;
}

.tabs button:nth-child(1) {
    border-right: 0;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.tabs button:nth-child(2) {
    border-left: 0;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.analytics-button {
    display: flex;
    align-items: center;
    gap: 10px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 14px;
    transition: all .3s;
    cursor: pointer;
    margin-left: auto;
    text-decoration: none;
}

.analytics-button:hover {
    color: #00c8ff;
}

.file-label {
    flex-direction: column;
    gap: 3px;
    align-items: flex-start;
}

.file-label .ant-upload-list, .file-label .ant-upload-list-item-name {
    color: #000;
}

.file-label path {
    fill: #000;
}

.reports-toggle-type {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 35px;
}

.reports-toggle-type button {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    transition: all .3s;
    text-transform: uppercase;
}

.reports-toggle-type button:hover {
    color: #00c8ff;
}

.reports-toggle-type button.is-active {
    pointer-events: none;
    color: #00c8ff;
    text-decoration: underline;
}

.ant-upload-list-item-name {
    width: 262px;
}

.new-report {
    margin-top: 50px;
    padding-bottom: 100px;
}

.new-report.container {
    max-width: 1570px;
}

.nr-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.nr-header__left {
    display: flex;
    align-items: center;
    gap: 30px;
}

.nr-header__logo {
    text-transform: uppercase;
    color: #fff;
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    width: 180px;
    min-width: 100px;
    font-size: 30px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    height: auto;

}

.ant-input-status-error.nr-header__logo {
    border-bottom: 1px solid red !important;
}

.nr-header__logo, .nr-header__logo input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.nr-header__logo::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.nr-header__info {
    max-width: 400px;
    width: 100%;
}

.nr-header__info > span {
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 10px;
    display: block;
    width: 100%;
}

.chk-custom .ant-checkbox-wrapper span:nth-child(2) {
    padding: 0;
}

.chk-custom .ant-checkbox-wrapper {
    align-items: center;
}

.nr-header__info .ant-form-item {
    margin-bottom: 10px;
    width: calc(100% / 2 - 20px);
}

.nr-header__info {
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
}

.nr-header__upload-photos {
    display: flex;
    gap: 15px;
}

.nr-body {
    display: flex;
    align-items: flex-start;
    gap: 20px 40px;
    flex-wrap: wrap;
    max-width: 1153px;
}

.nr-body__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 13px;
}

.nr-body__title {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 25px;
    width: 100%;
    margin-top: 20px;
}

.nr-body__titles {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
}

.nr-body__titles > div {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
}

.nr-body__titles > div:nth-child(5) {
    max-width: 74px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nr-body__titles > div.intensity{
    min-width: 139px;
}

.nr-body__titles > div:nth-child(5) svg {
    width: 20px;
}

.nr-body__player {
    position: relative;
}

.nr-btn {
    background: transparent;
    cursor: pointer;
    color: #fff;
}

.nr-btn.nr-btn--remove {
    font-size: 20px;
    color: red;
    border: none;
    transition: all .3s;
}

.nr-body__player .nr-btn.nr-btn--remove {
    right: -30px;
    top: 5px;
    position: absolute;

    opacity: 0;
    pointer-events: none;
}

.nr-body__player:hover .nr-btn.nr-btn--remove {
    opacity: 1;
    pointer-events: initial;
}

.nr-body__player .nr-btn.nr-btn--remove::before {
    content: '';
    width: 30px;
    height: 37px;
    top: -7px;
    right: 0;
    display: block;
    position: absolute;
}

.nr-btn--add {
    font-size: 13px;
    height: 30px;
    width: 150px;
    margin: 0;

}

.nr-btn.nr-btn--add:hover {
    color: #00c8ff;
}

.nr-container {
    display: flex;
}

.new-report__header-left {
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
}

.new-report__header-left span {
    display: block;
}

.new-report__header-left h3 {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 5px;
}

.new-report__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
    position: relative;
}

.new-report__header-right img {
    width: 120px;
    height: 120px;
    object-fit: contain;
}

.make-teams h2, .new-report__body h2 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}

.make-teams h3, .new-report__body h3 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
}

.make-teams__wrapper {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    justify-content: center;
}

.make-teams__list {
    list-style-type: none;
}

.make-teams__item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}

.make-teams__icons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.make-teams__icons svg {
    width: 15px;
    height: auto;
}

.new-report__screens {
    display: flex;
    gap: 20px;
}

.new-report__screens img {
    width: 300px;
}

.new-report__screens p {
    margin-bottom: 10px;
}

.statistic h3 {
    text-align: left;
}

.statistic__item {
    display: flex;
    gap: 20px;
}

.statistic__list {
    margin-bottom: 50px;
}


.statistic__item:not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 200, 255, 0.6);
}
.statistic__item.pdf:not(:last-child) {
    padding-bottom: 7px;
    margin-bottom: 7px;
    border-bottom: 1px solid rgba(0, 200, 255, 0.6);
}

.statistic__item:last-child {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 200, 255, 0.6);
}

.statistic__item:first-child {
    padding-bottom: 20px;
    border-top: none;
}

.statistic__item:first-child span {
    font-size: 10px;
}

.statistic__item span {
    font-size: 12px;
}

.goalkeepers {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 70px;
}

.goalkeepers__head {
    display: flex;
    gap: 30px;
    font-size: 28px;
}

.goalkeepers__head img {
    width: 250px;
    height: 375px;
    object-fit: contain;
}

.goalkeepers__head span {
    display: block;
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 700;
}


.goalkeepers__list {
    width: 400px;
}

.goalkeepers__list li {
    display: flex;
    align-items: center;
    gap: 10px;
}

.goalkeepers__list li:nth-child(1) {
    margin-bottom: 10px;
}

.goalkeepers__list li:not(:first-child) {
    padding-top: 3px;
    margin-top: 3px;
    border-top: 1px solid rgba(0, 200, 255, 0.6);
}

.goalkeepers__list li b {
    width: 200px;
    font-size: 10px;
}

.goalkeepers__list li span {
    width: 55px;
    text-align: center;
    font-size: 10px;
}

.lines-statistic {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
}

.lines-statistic-title {
    margin-top: 50px;
    margin-bottom: 30px !important;
}

.lines-statistic__item {
    text-align: center;
}

.lines-statistic__item img {
    width: 250px;
    height: 350px;
    object-fit: cover;
    object-position: center top;
}

.lines-statistic__item p {
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
}

.chart {
    width: 500px !important;
    height: 500px !important;
}

.btn-print {
    position: absolute;
    top: -30px;
    left: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    width: 45px;
}

.icon-firsttime:last-child{
    margin-left: 70px;
}