.footballField {
    width: 367px;
    height: 612px;
    position: relative;
}

.footballField.footballFieldSave {
    pointer-events: none;
}

.footballFieldImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
}

.point {
    position: absolute;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}