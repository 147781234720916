.header {
    padding: 20px 0;
}

.header:global > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.menuList {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    gap: 30px;
}

.menuItem {
    position: relative;
}

.menuItem a, .menuItem button {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .3s;
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.menuItem a:hover, .menuItem button:hover {
    color: #00c8ff;
}

.logo svg {
    width: 70px;
    height: initial;
}

.subMenu {
    pointer-events: none;
    opacity: 0;
    list-style-type: none;
    transition: all .3s;
    padding: 15px 10px;
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    position: absolute;
    top: 100%;
    right: 0;
    width: 180px;
    border: 1px solid #00c8ff;
    z-index: 100;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.subMenu::before {
    content: '';
    position: absolute;
    bottom: 100%;
    height: 15px;
    width: 100%;
}

.menuItem:hover > .subMenu {
    opacity: 1;
    pointer-events: initial;
}

.subMenu .menuItem a {
    white-space: nowrap;
    font-size: 14px;
}