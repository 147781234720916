.createUser {
    background: transparent;
    color: #fff;
    border: none;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    text-transform: uppercase;
    transition: all .3s;
}

.createUserIcon {
    width: 25px;
    height: 25px;
}

.createUserIcon path {
    fill: #fff;
    transition: all .3s;
}

.createUser:hover {
    color: #00c8ff;
}

.createUser:hover .createUserIcon path {
    fill: #00c8ff;
}

.usersList {
    list-style-type: none;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.usersItem {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #00c8ff;
    padding: 10px 30px;
    gap: 10px;
}

.usersItem span {
    max-width: 250px;
    width: 100%;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-left: auto;
}

.usersItemIcon {
    width: 25px;
    background: transparent;
    border: none;
    cursor: pointer;
}

.usersItemIcon svg {
    width: 100%;
}