.playerIcon {
    width: 100%;
    height: 200px;
    object-fit: contain;
    padding-top: 20px;
    pointer-events: none;
}

.playerImg {
    width: 100%;
    height: 200px;
    object-fit: contain;
    pointer-events: none;
    padding-top: 15px;
}

.inputFile[type="file"] {
    display: none !important;
}

.labelFile {
    width: 150px;
    height: 150px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    cursor: pointer;
}

.labelFileIcon {
    font-size: 40px;
    opacity: 0.7;
    transition: all 0.3s;
}

.labelFileIcon path {
    fill: #ccc;
    transition: all 0.3s;
}

.labelFile:hover {
    border: 1px dashed #00c8ff;
}

.labelFile:hover .labelFileIcon path {
    fill: #00c8ff;
}

.labelFile:hover .labelFileIcon {
    opacity: 1;
}

.uploadedImg {
    display: none;
    position: relative;
    width: 150px;
    height: 150px;
}

.uploadedImg img {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imgRemove {
    cursor: pointer;
    position: absolute;
    right: -3px;
    top: -3px;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 3px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploaded .uploadedImg {
    display: flex;
}

.uploaded .labelFile {
    display: none;
}

.teamsWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.cardWrapper {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    scrollbar-width: auto;
    scrollbar-color: transparent;
    margin: 0 25px 10px;
    padding-bottom: 10px;
    position: relative;
}

.cardWrapper::-webkit-scrollbar {
    height: 6px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
}

.cardWrapper::-webkit-scrollbar-track {
    background: transparent;
}

.cardWrapper::-webkit-scrollbar-thumb {
    background-color: #00c8ff;
    border-radius: 10px;
}

.card {
    min-width: 250px;
    width: 250px;
}

.cardContent {
    background: transparent;
    position: relative;
    z-index: 1;
    border: none;
    color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.cardContent::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(55px);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    z-index: -1;
}

.cardContent :global .ant-card-actions {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(55px);
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    margin-top: auto;
}

.row {
    position: relative;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    width: 100%;
    border: 2px solid #00c8ff;
}